import {handleError} from './helpers'
import apiServiceGenerator from "@/api/selectableGenerator";

export default function (endpoint) {
    return {
        state: {
            selectListLoading: false,
            selectListPager: {},
            selectListAdditional: {},
            selectList: [],
            service: apiServiceGenerator(endpoint)
        },
        getters: {
            selectListLoading: function (state) {
                return !!state.selectListLoading
            },
            selectListPager: function (state) {
                return JSON.parse(JSON.stringify(state.selectListPager))
            },
            selectAdditional: function (state) {
                return JSON.parse(JSON.stringify(state.selectAdditional))
            },
            selectList: function (state) {
                return state.selectList
            },
        },
        mutations: {
            'SET_SELECT_LIST_LOADING': function (state, boolean = true) {
                state.selectListLoading = boolean
            },

            'SET_SELECT_LIST': function (state, list) {
                state.selectList = list
            },

            'SET_SELECT_LIST_PAGER': function (state, pager = {}) {
                state.selectListPager = pager
            },

            'SET_SELECT_LIST_ADDITIONAL': function (state, data = {}) {
                state.selectListAdditional = data
            },
        },
        actions: {
            selectList: function ({commit, state}, filters) {
                commit('app/SET_LOADING', true, {root: true});
                commit('SET_SELECT_LIST_LOADING', true);

                return state.service.fetchSelectList(filters).then(response => {
                    commit('SET_SELECT_LIST', response.data);
                    commit('SET_SELECT_LIST_PAGER', response.meta);
                    commit('SET_SELECT_LIST_ADDITIONAL', response.additional);
                    return response.data;
                }).catch((e) => {
                    handleError(e, commit);
                }).finally(() => {
                    commit('app/SET_LOADING', false, {root: true});
                    commit('SET_SELECT_LIST_LOADING', false);
                });
            }
        }
    }
}